/*@import "@angular/material/prebuilt-themes/indigo-pink.css";*/
@import "../node_modules/angular-calendar/css/angular-calendar.css";
@import "./gembb-theme";

html,
body {
  height: 100%;
}

button.fixed {
  position: fixed;
  bottom: 15px;
  right: 15px;
}

.mat-button.active .mat-button-focus-overlay,
.mat-stroked-button.active .mat-button-focus-overlay {
  opacity: 1;
}

.fill-remaining {
  flex: 1 1 auto;
}

.full-width-input {
  width: 100%;
}

table td {
  padding: 10px;
}

.mat-dialog-content {
  padding: 10px 15px !important;
}

.mat-row.clickable {
  cursor: pointer !important;
}

.mat-column-select {
  max-width: 50px;
}

mat-optgroup.no-label label {
  display: none !important;
}

.main-container {
  position: absolute;
  top: 64px;
  left: 0px;
  right: 0;
  bottom: 0;
}

.push-right {
  #sidebar {
    left: 0 !important;
  }
}

.no-padding {
  header {
    width: 100%;
    height: 10vh;
    padding: 10px 15px;
    margin: 0;
  }

  .mat-dialog-container {
    padding: 0 !important;
  }

  .mat-dialog-actions {
    padding: 10px 15px;
    height: 10vh;
    max-height: 10vh;
    width: 100%;
    box-sizing: border-box;
    margin-bottom: 0 !important;
    /* display: block; */
    align-items: center;
    flex-wrap: unset;
  }

  .mat-dialog-content {
    margin: 0;
    width: 100%;
  }
}

.form-label {
  color: #0000008a;
  font-size: 14px;
  font-family: Roboto, "Helvetica Neue", sans-serif;
  font-weight: 400;
}

.editor-container {
  margin-bottom: 10px;
}

.mat-dialog-actions.flex {
  display: flex;
  justify-content: space-between;
}

.mat-toolbar.flex {
  display: flex;
  justify-content: space-between;
}

.chip {
  color: white;
  background-color: #696969;
  border-radius: 8px;
  padding: 0 15px;
  margin: 0 5px;
}

.chip.income {
  background: green;
}

.chip.outcome {
  background: red;
  white-space: nowrap;
}

.chip {
  &--draft {
    color: white;
    background: grey;
  }

  &--planned {
    color: white;
    background: lightseagreen
  }

  &--completed {
    color: white;
    background: darkgoldenrod;
  }

  &--cancelled {
    color: white;
    background: darkred;
  }

}

.mat-expansion-panel.invalid,
.mat-row.invalid {
  border: solid $warn 3px;

  &--top {
    border-bottom: unset;
  }

  &--center {
    border-top: unset;
    border-bottom: unset;
  }

  &--bottom {
    border-top: unset;
  }
}

.flex-row {
  display: flex;
  justify-content: space-between;
  width: 100%;
}

.additional-selection {
  opacity: 0.75;
  font-size: 0.75em;
}

.mat-toolbar .mat-form-field {
  margin: 0 5px;
  max-width: 100%;
}

.mat-cell .mat-form-field {
  max-width: 100%;
}

.mat-cell:not(:first-of-type):not(:last-of-type) {
  padding: 0 5px;
}

.mat-column-add {
  max-width: 75px;
  width: 75px;
}

.mat-snack-bar-container.error {
  background: red;
  color: white;
}

.mat-snack-bar-container.error .mat-simple-snackbar-action {
  color: white;
}

.multiline-tooltip {
  white-space: pre-line;
}

.u-full-width {
  width: 100%;
}

.u-flex-full {
  display: flex !important;
  justify-content: space-between;
  align-items: center;
  gap: 10px;
}

.u-flex-center {
  display: flex !important;
  justify-content: center;
  align-items: center;
}
.u-flex-full-space {
  display: flex !important;
  justify-content: space-between;
  align-items: flex-end;
}

.u-flex-column {
  display: flex !important;
  flex-direction: column;
}

.u-flex-align-center {
  display: flex !important;
  align-items: center;
}

.u-center-text {
  text-align: center;
}

.mat-slide-toggle-content {
  font-weight: normal !important;
}

th.mat-header-cell {
  padding: 0 5px !important;
}

.true-icon {
  color: rgb(0, 201, 0);
}


.warning-font {
  color: red;
}
